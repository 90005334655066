import { ReviewProvider } from "./constants/ReviewProvider";
import { ReviewSource } from "./constants/ReviewSource";
import { IReview, IReviewTripadvisor, IReviewTrustyou } from "./types";

export const getReviewSourceByProvider = (reviewProvider: ReviewProvider) => {
    switch (reviewProvider) {
        case ReviewProvider.TrustYou:
            return ReviewSource.TrustYou;
        case ReviewProvider.TripAdvisor:
            return ReviewSource.TripAdvisor;
        default:
            throw new Error(`Could not resolve review source by provider: ${reviewProvider}`);
    }
};

export const getReviewProviderBySource = (reviewSource: ReviewSource) => {
    switch (reviewSource) {
        case ReviewSource.TrustYou:
            return ReviewProvider.TrustYou;
        case ReviewSource.TripAdvisor:
            return ReviewProvider.TripAdvisor;
        default:
            throw new Error(`Could not resolve review provider by source: ${reviewSource}`);
    }
};

export function isTrustYouReviewType(review: IReview<unknown>): review is IReview<IReviewTrustyou> {
    return review.source === ReviewSource.TrustYou;
}

export function isTripAdvisorReviewType(review: IReview<unknown>): review is IReview<IReviewTripadvisor> {
    return review.source === ReviewSource.TripAdvisor;
}
