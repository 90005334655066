import { AvailabilityRequestType, availabilityTypes } from "@app/availability";
import { bookingTypes } from "@app/booking";
import { commonTypes } from "@app/common";
import { propertyTypes } from "@app/property";
import { reviewTypes } from "@app/review";
import { ReviewSource } from "@app/review/constants/ReviewSource";
import { createContext } from "react";
import { UseFormGetValues } from "react-hook-form";

import { IBookingEngineState } from "../types";

export interface IBookingEngineStateContext {
    getState: UseFormGetValues<IBookingEngineState>;
    resetBooking: () => void;
    getTotalPrice: (options: { discounted?: boolean }) => number;
    toggleExtra: (extra: commonTypes.IExtra) => void;
    getLengthOfStay: () => number;
    getExtraById: (id: string) => commonTypes.IExtra | undefined;
    getBookableItems: (sort: "pr" | "rp") => commonTypes.TBookableItem[];
    setCart: (cart: bookingTypes.ICart) => void;
    fetchCart: () => Promise<bookingTypes.ICart>;
    availability: availabilityTypes.IAvailability[];
    bookingExtras: commonTypes.IExtra[][];
    loadingExtras: boolean;
    getAvailabilityParams: (rt?: AvailabilityRequestType) => string;
    clearUrlParams: () => void;
    calendarAvailability: availabilityTypes.ICalendarAvailability;
    setActiveCalendarDate: (date: Date) => void;
    filteredBookableItems: commonTypes.TBookableItem[];
    isLoading: boolean;
    isCartInitialized: boolean;
    unconfirmedRate: commonTypes.IRate | undefined;
    cart: bookingTypes.ICart | undefined;
    property?: propertyTypes.IProperty;
    exchangeRates: commonTypes.IExchangeRates;
    initialized?: boolean;
    reviews: reviewTypes.IReview<unknown>;
    trackerReady: boolean;
}

export const BookingEngineStateContext = createContext<IBookingEngineStateContext>({
    getState: () => undefined as any,
    resetBooking: () => {},
    getTotalPrice: () => -1,
    toggleExtra: () => {},
    getLengthOfStay: () => -1,
    getExtraById: (id: string) => undefined,
    unconfirmedRate: undefined,
    getBookableItems: () => [],
    setCart: () => {},
    fetchCart: () => Promise.resolve({} as bookingTypes.ICart),
    availability: [],
    bookingExtras: [],
    loadingExtras: false,
    getAvailabilityParams: () => "",
    clearUrlParams: () => {},
    calendarAvailability: {},
    setActiveCalendarDate: () => {},
    filteredBookableItems: [],
    isLoading: false,
    isCartInitialized: false,
    cart: undefined,
    exchangeRates: {} as commonTypes.IExchangeRates,
    initialized: false,
    reviews: {
        reviews_based_on: 0,
        average_score: 0,
        reviews: [],
        total_score_title: "",
        source: ReviewSource.TrustYou,
    },
    trackerReady: false,
});
