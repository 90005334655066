import { appHooks, appTypes } from "@app/app";
import {
    ControlledFormField,
    CurrencySelect,
    Dialog,
    DialogHeader,
    DialogHeightModes,
    DialogVerticalPositions,
    Label,
    LanguageSelect,
} from "@app/common";
import { GlobeAltIcon } from "@heroicons/react/20/solid";
import { Badge, Button, Flex } from "@ui";
import { Fragment } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const fallbackCurrency = { id: "fallback-currency", label: "EUR", code: "EUR", symbol: "€" };

export const LanguageAndCurrencySelector = () => {
    const { control, getValues, setValue } = useFormContext<appTypes.IBookingEngineState>();
    const { getState } = appHooks.useBookingEngineStateContext();
    const { property } = getState();
    const { t } = useTranslation("common");
    const propertyLanguages = property?.languages || [];
    const propertyCurrencies = property?.currencies || [];
    const defaultCurrency = property?.settings.default_currency || fallbackCurrency;
    const selectedLang = getValues("language");
    const selectedCurr = getValues("currency");

    const changeLanguage = (lang: string) => {
        setValue("language", lang);
    };

    const changeCurrency = (currency: string) => {
        setValue("currency", currency);
    };

    return (
        <Dialog
            verticalPosition={DialogVerticalPositions.Bottom}
            heightMode={DialogHeightModes.Fit}
            triggerButton={
                <Button data-testid="global-icon-btn" variant="ghost" size="icon" className="text-gray-500">
                    <GlobeAltIcon width={20} />
                </Button>
            }
        >
            {({ close }) => (
                <Fragment>
                    <DialogHeader onClose={close}>{t("select")}</DialogHeader>
                    <div className="w-full p-4 pt-8 overflow-x-scroll">
                        <div data-testid="language-select-field" className="mx-auto mt-3 text-left">
                            <Label>{t("chooseLanguage")}</Label>
                            <ControlledFormField
                                name="language"
                                control={control}
                                component={LanguageSelect}
                                languages={propertyLanguages}
                            />
                        </div>
                        <Flex data-testid="default-languages" justifyContent="start" className="gap-x-2 mt-3">
                            {propertyLanguages.map((lang) => (
                                <Badge
                                    key={lang.code}
                                    variant={selectedLang === lang.code ? "secondary" : "outline"}
                                    className="cursor-pointer p-3"
                                    onClick={() => changeLanguage(lang.code)}
                                >
                                    {lang.label}
                                </Badge>
                            ))}
                        </Flex>

                        <div data-testid="currency-select-field" className="mx-auto mt-14  text-left">
                            <Label>{t("chooseCurrency")}</Label>
                            <ControlledFormField
                                name="currency"
                                control={control}
                                component={CurrencySelect}
                                currencies={propertyCurrencies}
                            />
                        </div>
                        <Flex data-testid="default-currencies" justifyContent="start" className="gap-x-1 mt-3">
                            {[defaultCurrency].map((cur, curIndex) => (
                                <Badge
                                    data-testid={`currency-item-${curIndex}`}
                                    color={selectedCurr === cur.code ? "primary" : "slate"}
                                    className="cursor-pointer p-3"
                                    key={cur.code}
                                    variant={selectedCurr === cur.code ? "secondary" : "outline"}
                                    onClick={() => changeCurrency(cur.code || "")}
                                >
                                    {`${cur.symbol} ${cur.code}`}
                                </Badge>
                            ))}
                        </Flex>

                        <Button data-testid="save-preference-btn" className="w-full mt-6" onClick={() => close()}>
                            {t("save")}
                        </Button>
                    </div>
                </Fragment>
            )}
        </Dialog>
    );
};
