import { WsServiceClient } from "@app/app";

import { ReviewProvider } from "./constants/ReviewProvider";
import * as reviewTypes from "./types";
import { getReviewSourceByProvider } from "./utils";

export const getReviews = async <TReviewType extends reviewTypes.IReview<unknown>>(
    propertyId: string,
    reviewProvider: ReviewProvider
): Promise<TReviewType> => {
    const reviewSource = getReviewSourceByProvider(reviewProvider);

    const response = await WsServiceClient.get<TReviewType>(`meta-reviews/${propertyId}/1/en?source=${reviewSource}`);

    return response.data;
};
