import { createContext, useCallback, useContext, useEffect, useState } from "react";

import { appElements } from "..";

type TThemeKey = "dark" | "light" | "system";

type TSize = "xxl" | "xl" | "lg" | "base" | "sm" | "xs";

export interface IOverrides {
    title: {
        [key in TSize]: IOverride;
    };
}

export interface IOverride {
    className?: string;
    style?: React.CSSProperties;
}

export interface ITheme {
    key: TThemeKey;
    overrides?: IOverrides;
}

export interface IThemeProviderProps {
    children: React.ReactNode;
    defaultTheme?: TThemeKey;
    storageKey?: string;
}

interface ThemeProviderState {
    theme: ITheme;
    setTheme: (theme: ITheme) => void;
}

const initialState: ThemeProviderState = {
    theme: { key: "system" },
    setTheme: () => null,
};

const ThemeProviderContext = createContext<ThemeProviderState>(initialState);

export function ThemeProvider({ children, defaultTheme = "system", storageKey = "hc-ibe-theme", ...props }: IThemeProviderProps) {
    const [theme, setTheme] = useState<ITheme>(() => {
        try {
            return JSON.parse(localStorage.getItem(storageKey) as string) || defaultTheme;
        } catch {
            return { key: defaultTheme };
        }
    });

    useEffect(() => {
        const root = appElements.container.querySelector<HTMLDivElement>("hc-ibe")?.shadowRoot?.firstChild as HTMLDivElement;

        if (root) {
            appElements.container.classList.remove("light", "dark");

            root.classList.remove("light", "dark");

            if (theme.key === "system") {
                const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";

                root.classList.add(systemTheme);

                return;
            }

            root.classList.add(theme.key);
        }
    }, [theme.key]);

    const setNextTheme = (nextTheme: ITheme) => {
        localStorage.setItem(storageKey, JSON.stringify(nextTheme));

        setTheme(nextTheme);
    };

    const value = {
        theme,
        setTheme: useCallback(setNextTheme, [setNextTheme]),
    };

    return (
        <ThemeProviderContext.Provider {...props} value={value}>
            {children}
        </ThemeProviderContext.Provider>
    );
}

export const useTheme = () => {
    const context = useContext(ThemeProviderContext);

    if (context === undefined) throw new Error("useTheme must be used within a ThemeProvider");

    return context;
};
