import { commonTypes } from "@app/common";
import { reviewTypes } from "@app/review/index";
import { QueryKeys } from "@app/review/QueryKeys";
import { useQuery } from "react-query";

import { ReviewProvider } from "./constants/ReviewProvider";
import { getReviews } from "./queries";

export const useGetReviews = (propertyId: string | undefined, reviewProvider: ReviewProvider, options?: commonTypes.IUseGetOptions) =>
    useQuery<reviewTypes.IReviewTrustyou, Error>(
        [QueryKeys.Reviews, propertyId, reviewProvider],
        () => getReviews(propertyId || "", reviewProvider),
        options
    );
