import { appHooks, appTypes, queryClient } from "@app/app";
import { useCustomNavigate } from "@app/app/hooks";
import { Alert, Button, Flex, Input, PromoCodeIcon, TagIcon, Text, ThemeTitle, TrashIcon } from "@ui";
import React, { Fragment, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { QueryKeys as CommonQueryKeys } from "../constants/QueryKeys";
import { useGetPromoDiscount } from "../hooks";
import { IPromoDiscount } from "../types";
import { Dialog, DialogHeader, DialogHeightModes, DialogVerticalPositions } from "./Dialog";
import { PriceableItemPrice } from "./PriceableItemPrice";

export const PromoCodeModal = () => {
    const navigate = useCustomNavigate();
    const { setValue } = useFormContext<appTypes.IBookingEngineState>();
    const { getState, isLoading, initialized } = appHooks.useBookingEngineStateContext();
    const { t } = useTranslation("common");
    const [codeValue, setCodeValue] = useState<string>("");
    const [code, setCode] = useState<string>("");
    const [redirect, setRedirect] = useState<boolean>(false);
    const { propertyId, promoCode, corpCode, activeStep } = getState();
    const {
        data: promoDiscount,
        isError: promoCodeError,
        isFetched,
    } = useGetPromoDiscount(promoCode || corpCode || code, propertyId || "", {
        enabled: !!code && !!propertyId,
    });
    const isPromoCode = !promoCodeError && promoDiscount?.type === "discount";
    const isCorpCode = !promoCodeError && promoDiscount?.type === "access";
    const hasValidCode = isPromoCode || isCorpCode;

    const codeAppliedMessage = isPromoCode ? t("promoCode") : isCorpCode ? t("corpCode") : "";
    const invalidCode = promoCodeError;

    const handleValueChange = (e: any) => {
        setCodeValue(e.currentTarget.value);
    };

    const onRemoveCode = () => {
        if (isPromoCode) {
            setValue("promoCode", undefined);

            queryClient.removeQueries([CommonQueryKeys.PromoDiscount]);
        } else if (isCorpCode) {
            setValue("corpCode", undefined);

            navigate("?p=base&c=availability");
        }

        setCode("");
    };

    const handleApplyPromoCode = () => {
        setCode(codeValue);
        setCodeValue("");
        if (isCorpCode) {
            setRedirect(true);
        }
    };

    useEffect(() => {
        if (!isLoading && initialized && (promoCode || corpCode)) {
            setCode(promoCode || corpCode || "");
        }
    }, [isLoading, initialized]);

    useEffect(() => {
        if (isPromoCode) {
            setValue("promoCode", code);
            setValue("corpCode", undefined);
        }
        if (isCorpCode) {
            setValue("corpCode", code);
            setValue("promoCode", undefined);

            if (redirect && activeStep !== "availability") {
                setRedirect(false);
                navigate("?p=base&c=availability");
            }
        }
        if (promoCodeError) {
            setValue("corpCode", undefined);
            setValue("promoCode", undefined);
        }
    }, [setValue, code, activeStep, redirect, isPromoCode, isCorpCode, promoCodeError]);

    return (
        <Dialog
            verticalPosition={DialogVerticalPositions.Bottom}
            heightMode={DialogHeightModes.Fit}
            triggerButton={
                <Button data-testid="discount-codes-btn" variant="ghost" size="icon" className="text-gray-500">
                    <PromoCodeIcon />
                </Button>
            }
        >
            {({ close }) => (
                <Fragment>
                    <DialogHeader onClose={close}>{t("specialRatesCodes")}</DialogHeader>

                    {hasValidCode && !!promoDiscount && (
                        <div className="absolute top-[-56px] w-full">
                            <Flex justifyContent="center">
                                <div>
                                    <div className="drop-shadow-xl bg-white border-1 border-green-200 rounded-lg py-3 px-4 text-sm font-medium text-green-300 flex">
                                        <TagIcon className="w-5 h-5 mr-2" />
                                        <DiscountFloatingLabel discount={promoDiscount} />
                                    </div>
                                </div>
                            </Flex>
                        </div>
                    )}

                    <div data-testid="discount-codes-modal" className="p-4 w-full max-h-fit overflow-x-scroll">
                        <Input
                            data-testid="discout-code-input"
                            className="mt-3"
                            placeholder={t("enterPromoCodePlaceholder")}
                            value={codeValue}
                            onChange={handleValueChange}
                        />
                        {hasValidCode && (
                            <Alert className="mt-4 p-0">
                                <Flex alignItems="center" justifyContent="between" className="w-full p-3">
                                    <Flex flexDirection="col" justifyContent="around" alignItems="start" className="gap-y-2">
                                        <ThemeTitle size="sm" className="font-medium text-primary text-sm">
                                            {codeAppliedMessage}
                                        </ThemeTitle>
                                        {!!promoDiscount && (
                                            <span data-testid="code-msg">
                                                <DiscountAmount discount={promoDiscount} />
                                            </span>
                                        )}
                                    </Flex>
                                    <Button
                                        data-testid={"remove-code-btn"}
                                        icon={TrashIcon}
                                        variant="ghost"
                                        size="sm"
                                        className="text-gray-600 font-medium px-1"
                                        iconPosition="right"
                                        onClick={onRemoveCode}
                                    >
                                        <span className="pr-2">{t("remove")}</span>
                                    </Button>
                                </Flex>
                            </Alert>
                        )}
                        {invalidCode && <Text className="text-xs text-red-400">{t("invalidCode")}</Text>}
                        <Button data-testid="apply-code-btn" disabled={!codeValue} className="w-full mt-10" onClick={handleApplyPromoCode}>
                            {t("applyPromoCode")}
                        </Button>
                    </div>
                </Fragment>
            )}
        </Dialog>
    );
};

const DiscountAmount = ({ discount }: { discount: IPromoDiscount }) => {
    const { t } = useTranslation("common");
    const isCorpCode = discount.type === "access";

    if (!isCorpCode && discount.discount_rate_type === 0) {
        return (
            <Text className="flex items-center text-xs text-gray-500">
                Got{" "}
                <span className="mx-1">
                    <PriceableItemPrice
                        plain={true}
                        moneyClassName="text-xs text-gray-500 inline-block"
                        item={Number(discount.discount_rate)}
                    />
                </span>{" "}
                {t("discount")}
            </Text>
        );
    }

    return (
        <Text className="text-xs text-gray-500">
            {!isCorpCode ? `Got ${discount.discount_rate}% ${t("discount")}` : t("hiddenRatesUnlocked")}
        </Text>
    );
};

const DiscountFloatingLabel = ({ discount }: { discount: IPromoDiscount }) => {
    const { t } = useTranslation("common");
    const isCorpCode = discount.type === "access";

    if (!isCorpCode && discount.discount_rate_type === 0) {
        return (
            <React.Fragment>
                <PriceableItemPrice
                    plain={true}
                    moneyClassName="inline text-sm font-medium text-green-300"
                    item={Number(discount.discount_rate)}
                />{" "}
                {t("discountApplied")}
            </React.Fragment>
        );
    }

    return (
        <Text className="text-sm font-medium text-green-300">
            {!isCorpCode ? `${discount.discount_rate}% ${t("discountApplied")}` : t("specialRatesUnlocked")}
        </Text>
    );
};
